import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { postcardMainSlideCss } from '../../organisms/PostcardContainer/PostcardContainer.styles';

export const PostcardHolder = styled.div`
  border: solid thin ${baseVariables.color.neutral20};
  border-radius: ${toRem(14)};
  contain: content;
  padding: 0;

  ${postcardMainSlideCss}

  .row {
    height: 100%;
    border-radius: ${toRem(14)};
  }
`;

export const PostcardContent = styled.div`
  flex: 0 0 80%;
  max-width: 80%;

  .t-subtitle-xl {
    color: ${baseVariables.color.neutral20};
  }

  .description-desktop {
    display: none;
  }

  .description-short {
    display: contents;
  }

  @media ${baseVariables.mediaQuery.md} {
    justify-content: end;
    padding: 0;

    .description-short {
      display: none;
    }

    .description-desktop {
      display: contents;
    }
  }
`;

export const ButtonWrapper = styled.div`
  gap: 1rem;
  margin-top: ${toRem(32)};
  width: max-content;
`;

export const PostcardTitle = styled.div`
  p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
  }

  margin-bottom: ${toRem(12)};
`;

export const PostcardSubtitle = styled.div`
  p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    font-size: ${toRem(18)};
    line-height: 1.25;
    margin-bottom: 0;
    overflow: hidden;

    @media ${baseVariables.mediaQuery.md} {
      font-size: ${toRem(29)};
    }
  }
`;

export const PostcardBackground = styled.div`
  border-radius: ${toRem(14)};
  height: 100%;
`;

// Taken from hero banner since they're using the image component
export const StyledImage = styled.div`
  .pc__img {
    display: block;
    filter: brightness(50%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;

    @supports (object-fit: cover) {
      object-fit: cover;
      width: 100%;
    }

    @media ${baseVariables.mediaQuery.md} {
      top: 0;
      transform: translate(-50%, 0);
      width: 100%;
    }
  }
`;
