import { EditableComponent } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';

import { HeroBanner as HeroBannerAEM } from './HeroBanner';
import { StyledHeroBannerWrapper } from './HeroBanner.styles';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const HeroBannerConfig = {
  emptyLabel: 'Homepage Hero Banner',
  isEmpty: () => true,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/homepage/herobanner`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HeroBanner = (props: any) => {
  const isTargetEnabled = props.model.styleclass?.includes('enable-target') && !props.isAuthorMode;
  return (
    <StyledHeroBannerWrapper
      data-testid="homepage-herobanner"
      className={clsx('herobanner-fullbleed', {
        [`target-background herobanner-ratio-${props.model.heroSize}`]: isTargetEnabled,
      })}
    >
      <EditableComponent config={HeroBannerConfig} {...props}>
        <HeroBannerAEM {...props.model} />
      </EditableComponent>
    </StyledHeroBannerWrapper>
  );
};
