import { baseVariables, Button, toRem } from '@marriott/mi-ui-library';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

const SLIDE_HEIGHT_MOBILE = 425;
const SLIDE_HEIGHT_MD = 335;
const SLIDE_WIDTH_MD = (16 / 9) * SLIDE_HEIGHT_MD;
const SLIDE_HEIGHT_LG = 532;
const SLIDE_WIDTH_LG = (16 / 9) * SLIDE_HEIGHT_LG;

export const postcardMainSlideCss = css`
  aspect-ratio: 2 / 3;
  height: ${toRem(SLIDE_HEIGHT_MOBILE)};

  @media ${baseVariables.mediaQuery.md} {
    aspect-ratio: 16 / 9;
    height: ${toRem(SLIDE_HEIGHT_MD)};
  }

  @media ${baseVariables.mediaQuery.lg} {
    height: ${toRem(SLIDE_HEIGHT_LG)};
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  align-items: center;

  .swiper-horizontal {
    width: 100%;
    height: auto;
  }
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  display: flex !important; // override out-of-the-box swiper styles
  flex-direction: column;
  position: relative;

  .swiper-wrapper {
    width: 70%;

    @media ${baseVariables.mediaQuery.lg} {
      width: 50%;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    width: fit-content;
    text-align: center;
    transition: transform 300ms;
    transform: scale(0.91);

    @media ${baseVariables.mediaQuery.lg} {
      width: auto;
    }

    .postcard-content {
      opacity: 0;
      transition: opacity 1s ease-out;
    }

    &.swiper-slide-active {
      position: relative;
      transform: scale(1);

      .postcard-content {
        opacity: 1;
        transition: opacity 1s ease-in, transform 0.8s ease-in-out;
      }
    }
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    align-self: center;
    bottom: 0;
    position: relative;
    width: fit-content;

    > span {
      vertical-align: middle;
    }

    .swiper-pagination-bullet {
      background-color: ${baseVariables.color.base10};
      height: ${toRem(6)};
      opacity: 0.7;
      transform: scale(1);
      transition: transform 750ms;
      width: ${toRem(6)};
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      transform: scale(1.7);
      transition: transform 750ms;
    }
  }

  .carousel-arrow-container {
    justify-content: space-evenly;

    .carousel-left-arrow,
    .carousel-right-arrow {
      background-color: transparent;
      position: relative;
    }

    @media ${baseVariables.mediaQuery.md} {
      .carousel-left-arrow,
      .carousel-right-arrow {
        background-color: ${baseVariables.color.base10};
        border-radius: 50%;
        height: 2.75rem;
        width: 2.75rem;
        color: ${baseVariables.color.base20};
        font-size: ${toRem(24)};
      }

      .carousel-left-arrow {
        transform: translate3d(-50%, -50%, 0);
      }

      .carousel-right-arrow {
        transform: translate3d(50%, -50%, 0);
      }
    }
  }
`;

export const StyledPlayPauseButton = styled(Button)`
  background: transparent;
  margin-right: 8px;
  padding: 0;
  width: fit-content;

  > div {
    align-content: flex-end;
    border: thin black solid;
    border-radius: 32px;
    height: 32px;
    width: 32px;
  }
`;

export const StyledArrowButtonsWrapper = styled.div`
  position: absolute;
  height: 0;
  justify-content: space-between;
  z-index: 1;

  @media ${baseVariables.mediaQuery.md} {
    aspect-ratio: 16 / 9;
    width: ${toRem(SLIDE_WIDTH_MD)};
    left: 50%;
    top: ${toRem(SLIDE_HEIGHT_MD / 2)};
    transform: translate3d(-50%, 0, 0);
  }

  @media ${baseVariables.mediaQuery.lg} {
    width: ${toRem(SLIDE_WIDTH_LG)};
    top: ${toRem(SLIDE_HEIGHT_LG / 2)};
  }
`;
