/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { StyleSheetManager } from 'styled-components';
import path from 'path';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import { useReportWebVitals } from 'next/web-vitals';

import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';
import '../styles/homepage.scss'; // TODO: once new Global nav implemention complete we have to remove this

import { defineComponentMapping } from '../import-components';
import {
  CustomModelClient,
  canUseDOM,
  respGridUtil,
  populatePIdataLayer,
  UserSessionProvider,
} from '@marriott/mi-headless-utils';
import {
  useClientEnvVarsStore,
  usePersistentGlobalStore,
  useDCAPageModelStore,
  useScrollStore,
} from '@marriott/mi-store-utils';
import { setSubDirectoryPrefix } from '@marriott/mi-shop-components/utils';
import { useRouter } from 'next/router';
import { version as globalStylesVersion } from '@marriott/global-styles/package.json';

path.resolve('./next.config.js');

declare global {
  interface Window {
    jQuery: unknown;
    $: any;
    deployedEnvType: string;
  }
}

const paramsString = canUseDOM && window.location.search;
const searchParams = new URLSearchParams(paramsString);
const modelClient = new CustomModelClient(process.env.NEXT_PUBLIC_AEM_HOST);

if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync(
    !(searchParams?.get('wcmmode') === 'disabled') && {
      modelClient,
    }
  );
}
const App = function (props) {
  const { Component, pageProps } = props;
  const rtlPluginTs = (...args: unknown[]) => {
    return rtlPlugin(args[0] as number, args[1] as string);
  };
  const {
    model,
    pagePath,
    isAuthorMode,
    requestId,
    currentLocale,
    apolloEnvVars,
    NEXT_PUBLIC_FETCH_OFFERS_API_URL,
    TARGET_PROPERTY_TOKEN,
    NEXT_GLOBAL_ASSET_PREFIX,
  } = pageProps;

  const { query } = useRouter();

  // This brandCode is just for local development and testing. It supplies the brandCode to load brandCSS below.
  // Do not use for production features!
  const devBrandCode = query.brandCode;

  // next performance analytics for more info: https://nextjs.org/docs/pages/api-reference/functions/use-report-web-vitals
  useReportWebVitals(metric => {
    // do not render performance analytics to the console in prod
    if (/dev|test|uat|perf/i.test(pageProps?.DEPLOYED_ENV_CONFIG)) {
      console.log('next/web-vitals - Metric: %s', metric.name, {
        metric,
      });
    }
  });
  const respGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);
  const respGridModelClone = JSON.parse(JSON.stringify(respGridModel)); // Deep clone the model

  const setPageModel = useDCAPageModelStore(state => state.setPageModel);
  setPageModel(respGridModelClone);

  const envVars = apolloEnvVars ? apolloEnvVars : {};
  envVars['NEXT_PUBLIC_FETCH_OFFERS_API_URL'] = NEXT_PUBLIC_FETCH_OFFERS_API_URL;
  envVars['TARGET_PROPERTY_TOKEN'] = TARGET_PROPERTY_TOKEN;
  envVars['SESSION_APP_POST_CALL_URL'] = pageProps?.SESSION_APP_POST_CALL_URL;
  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);
  const prodDNS = 'https://www.marriott.com';
  setEnvVars(envVars);

  if (canUseDOM) {
    //This code will populate the PI data related datalayer elements on clientside
    populatePIdataLayer();
  }

  useEffect(() => {
    if (!isAuthorMode) {
      return;
    }
    let remoteURL: URL | string;
    try {
      remoteURL = new URL(document.body.dataset.remoteUrl || '');
      remoteURL = remoteURL.origin;
    } catch (e) {
      remoteURL = '';
    }
    const assetUrl = `${process.env['NEXT_PUBLIC_ASSET_DOMAIN'] || remoteURL || prodDNS}${NEXT_GLOBAL_ASSET_PREFIX}`;
    const globalStyle = document.createElement('link');

    globalStyle.rel = 'stylesheet';
    globalStyle.href =
      assetUrl +
      (model?.rtlEnable == 'true'
        ? `global-styles/${globalStylesVersion}/marriot.global.rtl.css`
        : `global-styles/${globalStylesVersion}/marriot.global.css`);
    document.head.appendChild(globalStyle);
  }, []);

  const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData);
  useEffect(() => {
    updateGlobalData('sessionData', pageProps?.sessionData);
  }, [pageProps?.sessionData, updateGlobalData]);

  const responsivegrid = model?.cqItems?.root?.[':items']?.responsivegrid;

  const initialize = useScrollStore(state => state.initialize);

  if (!!responsivegrid && !isAuthorMode) {
    initialize(responsivegrid);
  }

  const allowedComponents = responsivegrid?.allowedComponents?.components;
  defineComponentMapping(allowedComponents, pagePath, isAuthorMode, pageProps);

  const mergeEnvVars = useClientEnvVarsStore((state: any) => state.mergeEnvVars);
  mergeEnvVars({
    SESSION_GET_CALL_URL_CLIENT: pageProps?.SESSION_GET_CALL_URL_CLIENT,
    NEXT_PUBLIC_SUBMIT_SEARCH_URL: pageProps?.NEXT_PUBLIC_SUBMIT_SEARCH_URL,
    NEXT_PUBLIC_AVAILABILITY_SEARCH_URL: pageProps?.NEXT_PUBLIC_AVAILABILITY_SEARCH_URL,
    GOOGLE_MAP_API_KEY: pageProps?.GOOGLE_MAP_API_KEY,
    UXL_IMAGE_CACHE_DOMAIN: pageProps?.UXL_IMAGE_CACHE_DOMAIN,
    SESSION_APP_GET_CALL_V1_URL: pageProps?.SESSION_APP_GET_CALL_V1_URL,
    BAIDU_MAP_API_KEY: pageProps?.BAIDU_MAP_API_KEY,
    REQUEST_ID: requestId,
    ACCEPT_LANGUAGE: currentLocale,
  });

  if (model) {
    setSubDirectoryPrefix(model.subDirectoryContextPath ? model.subDirectoryContextPath : '');
  }

  Object.defineProperty(rtlPluginTs, 'name', { value: 'rtlPluginMonorepo' });

  return (
    <>
      {devBrandCode && pageProps?.INJECT_BRAND_CSS === 'true' && (
        <link
          rel="stylesheet"
          href={`https://www.marriott.com/etc.clientlibs/mcom-book-spa/brandingcontent.${devBrandCode}.css`}
          as="style"
        />
      )}
      {process.env.NODE_ENV !== 'test' ? (
        <StyleSheetManager stylisPlugins={pageProps?.model?.rtlEnable === 'true' ? [rtlPluginTs] : []}>
          <UserSessionProvider pageProps={pageProps} includePiData={true}>
            <Component {...pageProps} />
          </UserSessionProvider>
        </StyleSheetManager>
      ) : (
        <UserSessionProvider pageProps={pageProps} includePiData={true}>
          <Component {...pageProps} />
        </UserSessionProvider>
      )}
    </>
  );
};

export default App;
