/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { useEffect, useRef, useState } from 'react';
import { A11y, Keyboard, Pagination, Autoplay } from 'swiper/modules';
import { Swiper } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/pagination';

import { respGridUtil } from '@marriott/mi-ui-library';
import { Postcard } from '../../molecules/Postcard';
import { PostcardProps } from '../../molecules/Postcard/Postcard.types';
import {
  StyledSwiper,
  StyledSwiperSlide,
  StyledPlayPauseButton,
  StyledArrowButtonsWrapper,
} from './PostcardContainer.styles';
import { PostcardContainerProps } from './PostcardContainer.types';
import { PostcardContainerButton } from './PostcardContainerButton';

import { getWCMModeFlagValue } from '@marriott/shared/mi-helper-utils';

const wcmModeDisabled = getWCMModeFlagValue();

const PostcardContainerComp = (props: PostcardContainerProps) => {
  const { trackingProperties, model } = props;
  const cqModel = respGridUtil(model);
  const cqPostcardsObject = respGridUtil(cqModel.cqItems[cqModel.cqItemsOrder[0]]);
  const postcardArray: PostcardProps[] =
    cqPostcardsObject.cqItemsOrder?.map((id: string) => cqPostcardsObject.cqItems[id]) || [];
  const trackingPropertiesObject = { ...trackingProperties, trackingDescription: '' };
  const swiperRef = useRef<any>(null);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [loop, setLoop] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [swiperTouched, setSwiperTouched] = useState(false);

  const handlePause = () => {
    swiperRef.current.autoplay.pause();
    setIsPaused(true);
  };

  // This is to handle an edge case on the navigation buttons where they don't pause
  const handleStop = () => {
    swiperRef.current.autoplay.stop();
    setIsPaused(true);
  };

  const handleResume = () => {
    // Related to edge case above
    if (!swiperRef.current.autoplay.running) {
      swiperRef.current.autoplay.start();
    }
    swiperRef.current.autoplay.resume();
    setIsPaused(false);
  };

  const togglePlayPause = () => {
    if (swiperRef.current.autoplay.paused || !swiperRef.current.autoplay.running) {
      handleResume();
      setSwiperTouched(false);
    } else {
      handlePause();
    }
  };
  const handleEventListener = (event: any) => {
    if (['ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
      setLoop(false);
      handlePause();
    } else setLoop(true);
  };

  // custom listener for tab and arrow events to set loop to true/false
  // a11y breaks the focus, so created custom event so that keyboard users can get out of the swiper carousel
  // if user clicks on arrows (or anything) the carousel can loop
  // if users tabs or uses right or left arrows, they can't loop
  useEffect(() => {
    document.addEventListener('keydown', handleEventListener);
    document.addEventListener('click', handleEventListener);

    return () => {
      document.removeEventListener('keydown', handleEventListener);
      document.removeEventListener('click', handleEventListener);
    };
  }, []);

  const commonNavigationButtonProps = {
    ...props,
    onClick: handleStop,
    trackingProperties: trackingPropertiesObject,
  };

  return props.isAuthorMode && !wcmModeDisabled ? (
    <ResponsiveGrid
      gridClassNames=""
      columnClassNames={{
        responsivegrid: '',
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pagePath={props?.pagePath}
      itemPath={`${props?.itemPath}/container-0`}
      config={{
        isEmpty: () => true,
        resourceType: 'mi-aem-common-spa/components/container',
      }}
    />
  ) : (
    <StyledSwiper
      onTouchEnd={() => {
        setSwiperTouched(true);
      }}
      onTransitionEnd={() => {
        if (swiperTouched) {
          handlePause();
        }
      }}
      onSwiper={(swiper: Swiper) => {
        swiperRef.current = swiper;
        setIsInitialized(true);
      }}
      slidesPerView="auto"
      centeredSlides
      pagination={{
        el: '.swiper-pagination', // Use a valid DOM element here
        type: 'bullets',
        enabled: true,
        clickable: false,
      }}
      modules={[Pagination, Keyboard, A11y, Autoplay]}
      keyboard={{ enabled: true }}
      loop={loop}
      autoplay={{
        delay: 5000,
        // Should be default, but isn't
        disableOnInteraction: false,
      }}
      speed={1000}
      breakpoints={{
        // based on styles from mi-phoenix-monorepo/libs/shared/mi-ui-global-styles/src/styles/base/variables/_mediaQueries.scss
        768: {
          spaceBetween: -2,
        },
        992: {
          spaceBetween: -12,
        },
      }}
    >
      {postcardArray.map((postcard, index) => (
        <StyledSwiperSlide
          key={index}
          tabIndex={0}
          onClick={handlePause}
          className={index === 0 && !isInitialized ? 'swiper-slide-active' : ''} // always apply active class to first slide for server render
        >
          <Postcard {...postcard} index={index} />
        </StyledSwiperSlide>
      ))}
      <StyledArrowButtonsWrapper className="d-none d-md-flex position-absolute">
        <PostcardContainerButton {...commonNavigationButtonProps} direction="left" />
        <PostcardContainerButton {...commonNavigationButtonProps} direction="right" />
      </StyledArrowButtonsWrapper>
      <div className="d-flex flex-row align-self-center mt-2 w-auto">
        <StyledPlayPauseButton callback={togglePlayPause}>
          <div data-testid="play-pause-button">
            <span className={isPaused ? 'icon-play icon-m' : 'icon-pause icon-m'} />
          </div>
        </StyledPlayPauseButton>
        <PostcardContainerButton {...commonNavigationButtonProps} direction="left" isMobile />
        <div className="swiper-pagination d-flex" />
        <PostcardContainerButton {...commonNavigationButtonProps} direction="right" isMobile />
      </div>
    </StyledSwiper>
  );
};

export const PostcardContainer = (props: PostcardContainerProps) => {
  return props.isAuthorMode ? (
    <EditableComponent
      config={{
        emptyLabel: 'postcardcontainer',
        isEmpty: () => false,
        resourceType: 'mi-aem-homepage-spa/components/content/postcardcontainer',
      }}
      {...props}
    >
      <PostcardContainerComp {...props} />
    </EditableComponent>
  ) : (
    <div className="m-container-fullbleed" style={{ clear: 'both' }}>
      <PostcardContainerComp {...props} />
    </div>
  );
};
