/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
import { produce } from 'immer';
import { createAppStore } from './zustand';

export interface DCAPageModelState {
  pageModel: unknown;
}
export interface DCAPageModelActions {
  setPageModel: (pageModel: unknown) => void;
  updateComponent: (componentKey: string, data: unknown, path: string) => void;
}

const initialState: DCAPageModelState = {
  pageModel: {},
};

export type DCAPageModelStoreType = ReturnType<typeof createDCAPageModelStore>;

export const createDCAPageModelStore: StateCreator<DCAPageModelState & DCAPageModelActions> = (set, get) => {
  return {
    ...initialState,
    setPageModel: (data: any) => {
      if (!Object.keys(get().pageModel as any).length) {
        set({ pageModel: data });
      }
    },
    updateComponent: (componentKey: string, data: unknown, path: string) => {
      set((state: DCAPageModelState) =>
        produce(state, (draft: any) => {
          if (draft.pageModel.cqItems) {
            const allKeys = path?.split('.') || [];
            allKeys.pop(); // Extract the last key for assignment
            const proxy = allKeys.reduce((acc, key) => acc?.[key], draft.pageModel);
            proxy[componentKey] = data;
          }
        })
      );
    },
  };
};

export const useDCAPageModelStore = createAppStore(createDCAPageModelStore);
