import { Button, Image, Types } from '@marriott/mi-ui-library';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import {
  ButtonWrapper,
  PostcardBackground,
  PostcardContent,
  PostcardHolder,
  PostcardSubtitle,
  PostcardTitle,
  StyledImage,
} from './Postcard.styles';
import { PostcardCTA, PostcardProps } from './Postcard.types';
import { POSTCARD_CONSTANTS } from '../../utils';
import { getClickTrackValue } from '../../utils/getClickTrackValue';

const PostcardComp = (props: PostcardProps) => {
  const { title, descriptionDesktop, descriptionShort, ctaLinkListItems, dynamicMedia, trackingProperties } = props;

  const trackingPropertiesObject = { ...trackingProperties, trackingDescription: '' };

  return (
    <PostcardHolder className="container border postcard-holder">
      <PostcardBackground className="d-flex align-items-center justify-content-center">
        <StyledImage data-testid="background">
          <Image
            defaultImageURL={dynamicMedia?.assetPath}
            altText={dynamicMedia?.altText}
            dynamic={dynamicMedia?.dynamic}
            renditions={dynamicMedia?.renditions}
            title={dynamicMedia?.altText}
            customClass="pc__img"
          />
        </StyledImage>
        <PostcardContent className="col-md-8 d-flex align-items-center flex-column postcard-content">
          <PostcardTitle className="t-title-inverse-m" dangerouslySetInnerHTML={{ __html: title }} />
          {descriptionShort && (
            <PostcardSubtitle
              className="t-subtitle-xl description-short"
              dangerouslySetInnerHTML={{
                __html: descriptionShort,
              }}
            />
          )}
          <PostcardSubtitle
            className="t-subtitle-xl description-desktop"
            dangerouslySetInnerHTML={{
              __html: descriptionDesktop,
            }}
          />

          <ButtonWrapper className="d-flex justify-content-center flex-column flex-md-row">
            {ctaLinkListItems?.map((postcardCTA: PostcardCTA, index) => {
              return (
                <Button
                  key={index}
                  href={postcardCTA?.ctaLinkURL}
                  isLink={true}
                  type={Types.ButtonTypeVariation.Button}
                  trackingProperties={trackingProperties}
                  className="m-button-s m-button-primary-inverse"
                  custom_click_track_value={getClickTrackValue({
                    trackingProperties: trackingPropertiesObject,
                    url: postcardCTA?.ctaLinkURL,
                    fallbacks: {
                      position: POSTCARD_CONSTANTS['POSITION'],
                      description: `Card ${props.index + 1} - Button ${index + 1}`,
                    },
                  })}
                >
                  {postcardCTA?.ctaLabel}
                </Button>
              );
            })}
          </ButtonWrapper>
        </PostcardContent>
      </PostcardBackground>
    </PostcardHolder>
  );
};

export const Postcard = (props: PostcardProps) => {
  return props.isAuthorMode ? (
    <EditableComponent
      config={{
        emptyLabel: 'postcard',
        isEmpty: () => false,
        resourceType: 'mi-aem-homepage-spa/components/content/postcard',
      }}
      {...props}
    >
      <PostcardComp {...props} />
    </EditableComponent>
  ) : (
    <PostcardComp {...props} />
  );
};
