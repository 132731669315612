//Basic hero banner skeleton loader to serve target puropose.

import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';

import { HeroBannerSkeletonLoaderProps } from './HeroBannerSkeletonLoader.types';
import { CopyBlockWidthEnum, HeroSize } from '../../../utils';

const { DEFAULT } = CopyBlockWidthEnum;
const { SIZE_16x9, SIZE_3x1, SIZE_2X1 } = HeroSize;

export const HeroBannerSkeletonLoader = ({
  width,
  copyBlockWidth,
  copyBlockWidthContainerClass,
  isVerticalImage,
  eyebrowtext,
  heroSize,
  cobrandmathhero,
  subheadingtext,
  secondaryctalabel,
  primaryctalabel,
  headerText,
  styleclass,
}: HeroBannerSkeletonLoaderProps) => {
  return (
    <div className={clsx('hero-banner hb__scrim', styleclass)}>
      <div
        className={clsx(
          'hb__cnt-sec gradient',
          !(styleclass?.includes('light-scrim') || styleclass?.includes('no-scrim')) ? copyBlockWidth : '',
          { 'overlay-text-hero3x1': heroSize === SIZE_3x1 }
        )}
      >
        <div className="container hb__cnt-ctr hero-3x1">
          {!isVerticalImage && eyebrowtext && (
            <div className="row">
              <div
                className={
                  copyBlockWidth === DEFAULT || (width && width < 576)
                    ? 'col-12 col-xl-5 col-md-6 col-lg-6'
                    : copyBlockWidthContainerClass
                }
              >
                <Skeleton width={`${eyebrowtext}ch`} />
              </div>
            </div>
          )}
          {headerText && (
            <div className="row">
              <div
                className={clsx(
                  copyBlockWidth === DEFAULT || (width && width < 576)
                    ? {
                        'col-12 col-lg-6 col-xl-5': !isVerticalImage && !cobrandmathhero,
                        'col-12 col-lg-12': isVerticalImage,
                        'col-sm-6 col-md-6': (heroSize === SIZE_16x9 || heroSize === SIZE_2X1) && !isVerticalImage,
                        'col-sm-5 col-md-6': heroSize === SIZE_3x1 && !isVerticalImage,
                        'col-12 col-lg-8 col-xl-6': !isVerticalImage && cobrandmathhero,
                      }
                    : copyBlockWidthContainerClass
                )}
              >
                <Skeleton height="2.5rem" width={`${headerText.length}ch`} />
              </div>
            </div>
          )}
          {subheadingtext && (
            <div className="row">
              <div
                className={clsx(
                  copyBlockWidth === DEFAULT || (width && width < 576)
                    ? {
                        'col-11 col-lg-6 col-xl-5': !isVerticalImage && !cobrandmathhero,
                        'col-12 col-lg-9 col-md-12 col-sm-auto ': isVerticalImage,
                        'col-sm-6 col-md-6': (heroSize === SIZE_16x9 || heroSize === SIZE_2X1) && !isVerticalImage,
                        'col-sm-5 col-md-6': heroSize === SIZE_3x1 && !isVerticalImage,
                        'col-9 col-lg-7 col-xl-6': !isVerticalImage && cobrandmathhero,
                      }
                    : copyBlockWidthContainerClass
                )}
              >
                <Skeleton height="2rem" width={`${subheadingtext}ch`} />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div
                className={clsx({
                  flexLayout: !isVerticalImage,
                })}
              >
                {primaryctalabel && <Skeleton height={'2rem'} width={`${primaryctalabel.length}ch`} />}
                {!isVerticalImage && secondaryctalabel && (
                  <Skeleton height={'2rem'} width={`${secondaryctalabel.length}ch`} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
