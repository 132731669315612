import { useSwiper } from 'swiper/react';
import { Button, TrackingPropsInterface } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { getClickTrackValue } from '../../utils/getClickTrackValue';
import { POSTCARD_CONSTANTS } from '../../utils';

type PostcardContainerButtonProps = {
  direction?: string;
  onClick: () => void;
  isMobile?: boolean;
  trackingProperties?: TrackingPropsInterface;
};

export const PostcardContainerButton = ({
  direction,
  onClick,
  isMobile,
  trackingProperties,
}: PostcardContainerButtonProps) => {
  const trackingPropertiesObject = { ...trackingProperties, trackingDescription: '' };
  const swiper = useSwiper();
  const isPrevious = direction === 'left';
  const arrowClassName = isPrevious ? 'carousel-left-arrow' : 'carousel-right-arrow';
  const keyMobileFallbackValue = isMobile ? '_BELOW' : '';
  const fallbacks = {
    position: POSTCARD_CONSTANTS['POSITION'],
    description:
      POSTCARD_CONSTANTS[isPrevious ? `LEFT_ARROW${keyMobileFallbackValue}` : `RIGHT_ARROW${keyMobileFallbackValue}`],
  };

  return (
    <div className={clsx('d-flex flex-row carousel-arrow-container', isMobile ? 'd-md-none' : 'inverse')}>
      <Button
        testId={arrowClassName}
        trackingProperties={trackingProperties}
        className={arrowClassName}
        ariaLabel={isPrevious ? 'Prev' : 'Next'}
        callback={() => {
          onClick?.();
          if (isPrevious) {
            return swiper.slidePrev();
          } else return swiper.slideNext();
        }}
        custom_click_track_value={getClickTrackValue({
          trackingProperties: trackingPropertiesObject,
          fallbacks,
        })}
      >
        <span className={isPrevious ? 'icon-arrow-left' : 'icon-arrow-right'} />
      </Button>
    </div>
  );
};
